

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #999;
$gray-600: #7A8288;
$gray-700: #52575C;
$gray-800: #3A3F44;
$gray-900: #272B30;
$black:    #000;

