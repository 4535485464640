

/** Просто флекс.
 */
 @mixin fx {
  display: flex;
}


/** Горизонтальный флекс с колонками на всю ширину.
 */
@mixin fxh {
  @include fx;
  flex-direction: row;
  width: 100%;
}


/** Предыдущее (.fxh) + перенос колонок.
 */
@mixin fxhw {
  @include fxh;
  flex-wrap: wrap;
}


/** Предыдущее (.fxhw) + централизация и отступы дочерних.
 */
@mixin fxhwc {
  @include fxhw;
  > * { align-self: center; }
}


/** (.fxh) + НЕ перенос колонок.
 */
@mixin fxhnw {
  @include fxh;
  flex-wrap: nowrap;
}


/** Предыдущее (.fxhnw) + централизация и отступы дочерних.
 */
@mixin fxhnwc {
  @include fxhnw;
  > * { align-self: center; }
}



/** Вертикальный флекс.
 */
 @mixin fxv {
  @include fx;
  flex-direction: column;
  height: 100%;
}

