

@import '../vars';


position-price {
  > span {
    &.rub {
      // font-weight: bold;
      // color: #fff;
      color: $color-highlight;
    }
  }
}

