

@import '../vars';


position-catalog-item {
  border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
  padding: 0.25em 0;
  div.sm {
    font-size: $font-sm;
  }
}


// div.tags {
//   font-size: $font-sm;
//   > span { margin-right: 0.25em; }
// }


em {
  color: $color-highlight;
}


position-catalog-item {
  div.f {
    line-height: 2em;
    font-weight: bold;
  }
}


position-catalog-item > div {
  flex: 1;
  margin: 0.25em 0;
}


position-catalog-item > div.l {
  max-width: 45%;
  padding: 0.5em 0;
  > div.sku-brand-model {
    > div.sku {
      color: #fff;
      background: rgba($color: #fff, $alpha: 0.1);
      margin-right: 0.5em;
      padding: 0 0.5em;
      border-radius: 3px;
    }
    > div.brand-model {
      color: #0cd;
    }
  }
}


position-catalog-item > div.r {
  div.stock {
    background: rgba($color: #fff, $alpha: 0.1);
    padding: 0.5em 1em;
    border-radius: 3px;
    margin-bottom: 0.25em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


position-catalog-item {
  div.position-delivery {
    font-weight: bold;
    > span {
      color: $color-highlight;
    }
  }
}

