

@import '../vars-font';


position-catalog {
  > div.time {
    font-size: $font-sm;
  }
}

