

@import '../vars';


position-qty {
//   display: block;
  &.out-of-stock {
    font-weight: normal;
  }
  > span {
    color: $color-highlight;
  }
}

