@charset "UTF-8";
/** Просто флекс.
 */
/** Горизонтальный флекс с колонками на всю ширину.
 */
/** Предыдущее (.fxh) + перенос колонок.
 */
/** Предыдущее (.fxhw) + централизация и отступы дочерних.
 */
/** (.fxh) + НЕ перенос колонок.
 */
/** Предыдущее (.fxhnw) + централизация и отступы дочерних.
 */
/** Вертикальный флекс.
 */
cbr-date {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap; }
  cbr-date > * {
    align-self: center; }
  cbr-date input {
    width: 7em !important;
    border: 0; }
  cbr-date ngb-datepicker {
    background: #e9ecef !important; }
  cbr-date > div:first-child {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    flex: 1;
    font-size: 1.2em; }
    cbr-date > div:first-child > * {
      margin-right: 0.5em; }
    cbr-date > div:first-child span:first-child {
      margin-right: 0.1em; }
    cbr-date > div:first-child span:last-child {
      color: #fff; }
