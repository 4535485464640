

console-router {
  display: block;
  margin-top: 0.5em;
  input {
    font-size: 1.5rem !important;
  }
}

