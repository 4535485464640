

div#Calculator {
  color: #fff;
  background: rgba($color: #fff, $alpha: 0.1);
  font-size: 1.2em;
  line-height: 2em;
  padding: 0 0.5em;
  border-radius: 3px;
  margin: 0.5em 0;
  i {
    margin-right: 0.5em;
  }
}

